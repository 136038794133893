@import url(https://fonts.googleapis.com/css?family=Roboto);
.container {
  width: 100%;
  padding-bottom: 25px; }

.content {
  background-color: #ffffff; }

.k-button {
  background-color: transparent !important;
  color: #FFFFFF !important;
  border: none !important;
  font-size: 18px !important; }

.k-button > .k-icon {
  font-size: 28px; }

.clsStickContainer {
  position: -webkit-sticky;
  position: sticky;
  top: 64px;
  z-index: 10;
  background-color: #FFFFFF;
  padding-bottom: 10px;
  padding-top: 5px;
  margin-top: -5px; }

.ant-layout-content {
  padding: 0px; }

.tileWrapper {
  display: 'flex';
  -webkit-flex-direction: 'column';
          flex-direction: 'column';
  width: 100%; }

.tile {
  -webkit-flex: 1 1;
          flex: 1 1;
  float: left;
  padding: 5px; }

.tile-content {
  border: 1px solid #C0C0C0;
  border-radius: 10px;
  height: 300px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center; }

.overlay {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 25px;
  color: #FFFFFF;
  transition: 0.5s; }

.text-container {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: rgba(27, 61, 120, 0.75);
  padding: 5px;
  padding-right: 30px;
  margin-top: 150px;
  margin-right: -25px;
  margin-left: 15%;
  text-align: right; }

.overlay:hover {
  background-color: rgba(0, 0, 0, 0.13); }

.titleWrapper {
  width: 100%;
  height: 40px;
  padding-top: 2px;
  border-bottom: 1px solid #C0C0C0; }

.titleWrapper h1 {
  font-size: 140%;
  line-height: 1;
  margin: 0;
  padding-top: 5px; }

.pullright {
  float: right; }

.pullleft {
  float: left; }

.searchTextbox {
  border: 1px solid #C0C0C0;
  border-radius: 0px !important;
  vertical-align: top;
  height: 28px !important; }

.searchProductTextbox {
  border: 1px solid #C0C0C0;
  width: 80%;
  height: 40px !important;
  padding-left: 8px;
  border-radius: 0px !important;
  vertical-align: top; }

.searchProductButton {
  border: 1px solid #C0C0C0;
  width: 20%;
  height: 40px !important;
  vertical-align: top; }

.searchProductButton:hover {
  background: #1890ff;
  color: #FFFFFF; }

.notFound {
  color: #808080;
  padding-top: 50px;
  text-align: center; }

.clsSideMenu {
  width: 250px;
  background-color: '#ffffff';
  overflow: auto;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  float: left; }

.clsMainArea {
  background: '#fff';
  float: left; }

.ant-menu-submenu-horizontal .ant-menu-submenu-title {
  height: 65px !important;
  line-height: 65px !important; }

.ant-menu-submenu-horizontal .ant-menu-submenu-title:hover {
  color: #FFFFFF !important; }

.clsOffer {
  min-height: 100px !important;
  height: auto !important; }

.clsOffer .shelf-item {
  margin-bottom: 0px !important;
  width: 250px !important;
  float: left; }

.clsOfferItemContainer {
  overflow-y: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 15px; }

.capitalizeFirstLetter {
  display: inline-block; }

.capitalizeFirstLetter:first-letter {
  text-transform: uppercase; }

.ant-table-tbody > tr > td {
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 90%; }

.ant-table-thead > tr > th {
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #eeeeee; }

div.toggleScroll-x, table.toggleScroll-x, td.toggleScroll-x, body.toggleScroll-x {
  overflow-x: hidden; }

div.toggleScroll-x:hover, table.toggleScroll-x:hover, td.toggleScroll-x:hover, body.toggleScroll-x:hover {
  overflow-x: overlay; }

div.toggleScroll-y, table.toggleScroll-y, td.toggleScroll-y, body.toggleScroll-y {
  overflow-y: hidden; }

div.toggleScroll-y:hover, table.toggleScroll-y:hover, td.toggleScroll-y:hover, body.toggleScroll-y:hover {
  overflow-y: overlay; }

.toggleScroll-x::-webkit-scrollbar {
  width: 13px; }

.toggleScroll-x::-webkit-scrollbar * {
  background: transparent; }

.toggleScroll-x::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.25) !important; }

.toggleScroll-y::-webkit-scrollbar {
  width: 13px; }

.toggleScroll-y::-webkit-scrollbar * {
  background: transparent; }

.toggleScroll-y::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.25) !important; }

@supports (-ms-ime-align: auto) {
  div.toggleScroll-x:hover, table.toggleScroll-x:hover, td.toggleScroll-x:hover {
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    scrollbar-width: thin; }
  div.toggleScroll-y:hover, table.toggleScroll-y:hover, td.toggleScroll-y:hover {
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    scrollbar-width: thin; } }

@-moz-document url-prefix() {
  div.toggleScroll-x, table.toggleScroll-x, td.toggleScroll-x {
    overflow-x: auto; }
  div.toggleScroll-y, table.toggleScroll-y, td.toggleScroll-y {
    overflow-y: auto; } }

.col-1 {
  width: 8.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 768px) {
  /* For medium screens: */
  [class*="col-"] {
    width: 50%; }
  .filters {
    width: 100% !important;
    text-align: left; }
  .ant-menu {
    width: 100% !important; }
  .clsSideMenu {
    width: 98% !important;
    margin-left: 1% !important;
    height: auto !important;
    position: relative !important;
    border: 1px solid #ececec;
    float: none; }
  .clsMainArea {
    float: none; }
  .ant-menu-submenu-inline .ant-menu-submenu-title {
    height: 20px !important;
    line-height: 20px !important; }
  .clsMainArea {
    margin-left: 0px !important; }
  .searchProductTextbox {
    width: 75%; }
  .searchProductButton {
    width: 25%; }
  .searchProductTextbox, .searchProductButton {
    font-size: 90%; }
  .container {
    padding-bottom: 0px !important; }
  main {
    padding: 0px !important;
    width: 98% !important;
    margin-left: 1% !important; } }

@media only screen and (max-width: 640px) {
  /* For mobile phones: */
  [class*="col-"] {
    width: 100%; } }

.headerWrapper {
  display: -webkit-flex;
  display: flex;
  margin: -2px auto 12px auto; }

.ant-layout-header {
  background: #1B3D78 !important;
  padding-left: 10px !important; }

.logo-corner-mini img {
  display: none; }

.quotesButton-mini {
  display: none !important; }

.searchButton-mini {
  display: none !important; }

.shelf-container-header {
  padding: 6px 0; }

.logo-corner h1 {
  margin: 0; }

input:not([type="range"]) {
  height: 38px;
  color: #333333;
  font-weight: 400; }

.logo-corner:hover .octo-arm {
  -webkit-animation: octocat-wave 560ms ease-in-out;
          animation: octocat-wave 560ms ease-in-out; }

.searchButton {
  height: 28px !important;
  border: 1px solid #C0C0C0;
  width: 80px;
  vertical-align: top; }

.searchButton:hover {
  background: #1890ff;
  color: #ffffff;
  border: 1px solid #0971d1; }

.searchTextbox {
  max-width: 200px; }

.header-right {
  padding-left: 20px; }

@-webkit-keyframes octocat-wave {
  0%,
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  20%,
  60% {
    -webkit-transform: rotate(-25deg);
            transform: rotate(-25deg); }
  40%,
  80% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg); } }

@keyframes octocat-wave {
  0%,
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  20%,
  60% {
    -webkit-transform: rotate(-25deg);
            transform: rotate(-25deg); }
  40%,
  80% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg); } }

@media (max-width: 500px) {
  .logo-corner:hover .octo-arm {
    -webkit-animation: none;
            animation: none; }
  .logo-corner .octo-arm {
    -webkit-animation: octocat-wave 560ms ease-in-out;
            animation: octocat-wave 560ms ease-in-out; } }

@media (min-width: 1025px) {
  .divSearchButton {
    display: none !important; } }

@media (max-width: 1024px) {
  .searchButton-mini {
    display: block !important; }
  .divSearchButtonHeader {
    display: none !important; } }

@media (max-width: 768px) {
  .ant-layout-header {
    padding: 0;
    background: #1B3D78 !important; }
  .logo-corner img {
    display: none; }
  .logo-corner-mini img {
    display: block;
    width: auto; }
  .quotesButton {
    display: none !important; }
  .quotesButton-mini {
    display: block !important; }
  .searchTextbox {
    font-size: 90%;
    max-width: 100px; }
  .searchButton {
    font-size: 80%; }
  .sort select {
    font-size: 90%; }
  .header-left {
    width: 20% !important; }
  .icon-link {
    padding: 0 !important; }
  .header-left {
    height: 60px; }
  .header-right {
    text-align: left !important;
    padding-left: 0px; }
  header a {
    font-size: 12px !important; }
  header {
    padding: 0px !important; } }

@media (max-width: 350px) {
  .logo-corner-mini img {
    height: 35px;
    width: auto; }
  .clsLogoContainer .k-card .k-card-body {
    padding: 0px;
    padding-left: 15px; } }

.clsCarouselElement {
  cursor: pointer; }

.clsHotTopic {
  border: 1px solid #ffffff;
  color: #ffffff;
  float: left;
  width: 25%;
  height: 150px;
  line-height: 150px;
  text-align: center;
  vertical-align: middle;
  border-radius: 5px;
  margin-bottom: 15px;
  background-size: cover;
  background-position: center;
  cursor: pointer; }

.clsHotTopic img {
  width: auto;
  height: 100%; }

@media (max-width: 1024px) {
  .clsHotTopic {
    width: 50%;
    height: 110px;
    line-height: 110px; } }

@media (max-width: 768px) {
  .clsHotTopic {
    width: 50%;
    height: 80px;
    line-height: 80px; } }

.float-cart {
  position: fixed;
  top: 0;
  right: -450px;
  width: 450px;
  height: 100%;
  background-color: #1b1a20;
  box-sizing: border-box;
  z-index: 100;
  transition: right 0.2s; }
  .float-cart__quantity {
    max-width: 80px;
    background-color: #f8ffbd;
    padding-left: 5px;
    color: #000; }
  .float-cart--open {
    right: 0; }
  .float-cart__close-btn {
    width: 50px;
    height: 50px;
    color: #ececec;
    background-color: #1b1a20;
    text-align: center;
    line-height: 50px;
    position: absolute;
    top: 0;
    left: -50px;
    cursor: pointer; }
    .float-cart__close-btn:hover {
      background-color: #212027; }
  .float-cart .bag {
    width: 40px;
    height: 40px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAQAAADZc7J/AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfiCwwQEgYn7+gWAAABQklEQVRIx9WTsUoDQRRF72xMYSFokcRCjBFESGWRwtZe8AtEFFNrI1bB0tLGQuzs/AJB8AO2kZRCxBiRQNKIoIYoyLFwCZuss9nNgpBXzc7be3j3zow09mU48321zVF8gNtbZ5Q1UwmGYQPIxFU5vnVdUuHfARO+OD5oa9GzM6NcbICkem+CLZ0kA1zKHQ2w6tlpqRUN4AwA5knFiTAISGsuHqDfwoOkgp4kUkoP1WI+Azt02ZYk9hle3cAEBh69c7iKEOJ30IJU/71KpqbaKCH6b0LEGgTcaYXpeIj+GJf54pyI70CSTACxq1M5ehmqvDCHfwIkFrQW4S1WzXUCq+E5lNhkMqSfo0ze3t7hlWeqIfJ3GnQo2n644ZhZYMnSL9OQcKnYAHs0ueUeY+nn6eDyRskGcFjngGxIBkUqVvk41g+oBJ136GBf8AAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOC0xMS0xMlQxNjoxODowNiswMTowMEVm3zEAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTgtMTEtMTJUMTY6MTg6MDYrMDE6MDA0O2eNAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center; }
    .float-cart .bag--float-cart-closed {
      position: absolute;
      background-color: #000;
      background-size: 50%;
      left: -45px;
      width: 45px;
      height: 90px;
      cursor: pointer; }
      .float-cart .bag--float-cart-closed .bag__quantity {
        bottom: 5px;
        right: 2px; }
    .float-cart .bag__quantity {
      display: inline-block;
      min-width: 18px;
      height: 18px;
      padding: 6px;
      padding-top: 0px;
      color: #0c0b10;
      font-weight: bold;
      font-size: 0.7em;
      text-align: center;
      line-height: 18px;
      border-radius: 50%;
      background-color: #eabf00;
      position: absolute;
      bottom: -5px;
      right: 0px; }
  .float-cart__header {
    color: #ececec;
    box-sizing: border-box;
    text-align: center;
    padding: 6px 0; }
    .float-cart__header .header-title {
      font-weight: bold;
      font-size: 1.2em;
      vertical-align: middle; }
  .float-cart__shelf-container {
    position: relative;
    min-height: 280px;
    padding-bottom: 150px; }
    .float-cart__shelf-container .shelf-empty {
      color: #ececec;
      text-align: center;
      line-height: 40px; }
    .float-cart__shelf-container .shelf-item {
      position: relative;
      box-sizing: border-box;
      padding: 5%;
      padding-bottom: 7px;
      padding-top: 7px;
      transition: background-color 0.2s, opacity 0.2s; }
      .float-cart__shelf-container .shelf-item::before {
        content: '';
        width: 90%;
        height: 2px;
        background-color: rgba(0, 0, 0, 0.2);
        position: absolute;
        top: 0;
        left: 5%; }
      .float-cart__shelf-container .shelf-item--mouseover {
        background: #0c0b10; }
        .float-cart__shelf-container .shelf-item--mouseover .shelf-item__details .title,
        .float-cart__shelf-container .shelf-item--mouseover .shelf-item__details .desc {
          text-decoration: line-through;
          opacity: 0.6; }
        .float-cart__shelf-container .shelf-item--mouseover .shelf-item__price {
          text-decoration: line-through;
          opacity: 0.6; }
      .float-cart__shelf-container .shelf-item__del {
        width: 16px;
        height: 16px;
        top: 7px;
        right: 5%;
        border-radius: 50%;
        position: absolute;
        background-size: auto 100%;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAOCAYAAADT0Rc6AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpBMjgxMDE2MDVBNDcxMUVBODQ0RkQxQTEzMjhBNEFEQiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpBMjgxMDE2MTVBNDcxMUVBODQ0RkQxQTEzMjhBNEFEQiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkEyODEwMTVFNUE0NzExRUE4NDRGRDFBMTMyOEE0QURCIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkEyODEwMTVGNUE0NzExRUE4NDRGRDFBMTMyOEE0QURCIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+UIT+xwAAASpJREFUeNqcVFsSwiAMDB2voz/eSTtwoNKeSb+8jzHg4sTwsGNmdiSQ7ZKYQMxMAi9YBA5+Cw4xXu/fiLxgEXS56QwxmZs2g+DJb4sdYYczRqzHx4LgKWBBbAlDMCImxfqJiI4CR2+7ChblE9YLzop/wrri3p37cLGuuchi5W8rGesMi62lGshiRRasMzYZFqRYl8m4QRRcVIaryqDYJpgFbLLZzT2XG6vULZlGgj+EqSWYvUbD2FJ/lfRHh9pSf0qqYyfaZ0z/W8WdTJf2StTq6r3lrbp6GghugCZHK9wRbHKL8GEgOCv/Yn5zQw0Eu1zhzDSaw0FzRTRPHDVNb45TeR/qz26NBWNvU/4D64p7VjOIdc3FrcLg3bXvbzCjEnrvbuP9zdyXAAMAJgkaAqlPSBUAAAAASUVORK5CYII=);
        background-repeat: no-repeat;
        z-index: 100;
        cursor: pointer; }
        .float-cart__shelf-container .shelf-item__del:hover {
          background-position-x: -17px; }
      .float-cart__shelf-container .shelf-item__thumb, .float-cart__shelf-container .shelf-item__details, .float-cart__shelf-container .shelf-item__price {
        display: inline-block;
        vertical-align: middle; }
      .float-cart__shelf-container .shelf-item__thumb {
        vertical-align: middle;
        width: 15%;
        margin-right: 3%; }
        .float-cart__shelf-container .shelf-item__thumb img {
          width: 100%;
          height: auto;
          border-radius: 4px; }
      .float-cart__shelf-container .shelf-item__details {
        width: 57%; }
        .float-cart__shelf-container .shelf-item__details .title {
          color: #ececec;
          margin: 0; }
        .float-cart__shelf-container .shelf-item__details .desc {
          color: #5b5a5e;
          margin: 0; }
      .float-cart__shelf-container .shelf-item__price {
        color: #eabf00;
        text-align: right;
        width: 25%;
        padding-top: 15px; }
    .float-cart__shelf-container .shelf-single-item {
      position: relative;
      box-sizing: border-box;
      padding: 5%;
      transition: background-color 0.2s, opacity 0.2s; }
      .float-cart__shelf-container .shelf-single-item::before {
        content: '';
        width: 90%;
        height: 2px;
        background-color: rgba(0, 0, 0, 0.2);
        position: absolute;
        top: 0;
        left: 5%; }
      .float-cart__shelf-container .shelf-single-item--mouseover {
        background: #0c0b10; }
        .float-cart__shelf-container .shelf-single-item--mouseover .shelf-item__details .title,
        .float-cart__shelf-container .shelf-single-item--mouseover .shelf-item__details .desc {
          text-decoration: line-through;
          opacity: 0.6; }
        .float-cart__shelf-container .shelf-single-item--mouseover .shelf-item__price {
          text-decoration: line-through;
          opacity: 0.6; }
      .float-cart__shelf-container .shelf-single-item__del {
        width: 16px;
        height: 16px;
        top: 7px;
        right: 5%;
        border-radius: 50%;
        position: absolute;
        background-size: auto 100%;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAOCAYAAADT0Rc6AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpBMjgxMDE2MDVBNDcxMUVBODQ0RkQxQTEzMjhBNEFEQiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpBMjgxMDE2MTVBNDcxMUVBODQ0RkQxQTEzMjhBNEFEQiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkEyODEwMTVFNUE0NzExRUE4NDRGRDFBMTMyOEE0QURCIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkEyODEwMTVGNUE0NzExRUE4NDRGRDFBMTMyOEE0QURCIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+UIT+xwAAASpJREFUeNqcVFsSwiAMDB2voz/eSTtwoNKeSb+8jzHg4sTwsGNmdiSQ7ZKYQMxMAi9YBA5+Cw4xXu/fiLxgEXS56QwxmZs2g+DJb4sdYYczRqzHx4LgKWBBbAlDMCImxfqJiI4CR2+7ChblE9YLzop/wrri3p37cLGuuchi5W8rGesMi62lGshiRRasMzYZFqRYl8m4QRRcVIaryqDYJpgFbLLZzT2XG6vULZlGgj+EqSWYvUbD2FJ/lfRHh9pSf0qqYyfaZ0z/W8WdTJf2StTq6r3lrbp6GghugCZHK9wRbHKL8GEgOCv/Yn5zQw0Eu1zhzDSaw0FzRTRPHDVNb45TeR/qz26NBWNvU/4D64p7VjOIdc3FrcLg3bXvbzCjEnrvbuP9zdyXAAMAJgkaAqlPSBUAAAAASUVORK5CYII=);
        background-repeat: no-repeat;
        z-index: 100;
        cursor: pointer; }
        .float-cart__shelf-container .shelf-single-item__del:hover {
          background-position-x: -17px; }
      .float-cart__shelf-container .shelf-single-item__thumb, .float-cart__shelf-container .shelf-single-item__details, .float-cart__shelf-container .shelf-single-item__price {
        display: inline-block;
        vertical-align: middle; }
      .float-cart__shelf-container .shelf-single-item__thumb {
        vertical-align: middle;
        width: 15%;
        margin-right: 3%; }
        .float-cart__shelf-container .shelf-single-item__thumb img {
          width: 100%;
          height: auto;
          border-radius: 4px; }
      .float-cart__shelf-container .shelf-single-item__details {
        width: 57%; }
        .float-cart__shelf-container .shelf-single-item__details .title {
          color: #ececec;
          margin: 0; }
        .float-cart__shelf-container .shelf-single-item__details .desc {
          color: #5b5a5e;
          margin: 0; }
      .float-cart__shelf-container .shelf-single-item__price {
        color: #eabf00;
        text-align: right;
        width: 25%; }
  .float-cart__footer {
    box-sizing: border-box;
    padding: 10px;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: auto;
    z-index: 100;
    background-color: #1b1a20; }
    .float-cart__footer::before {
      content: '';
      width: 100%;
      height: 20px;
      display: block;
      position: absolute;
      top: -20px;
      left: 0;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.2), transparent); }
    .float-cart__footer .sub,
    .float-cart__footer .sub-price {
      color: #5b5a5e;
      vertical-align: middle;
      display: inline-block; }
    .float-cart__footer .sub {
      width: 20%; }
    .float-cart__footer .sub-price {
      width: 80%;
      text-align: right; }
      .float-cart__footer .sub-price__val, .float-cart__footer .sub-price__installment {
        margin: 0; }
      .float-cart__footer .sub-price__val {
        color: #eabf00;
        font-size: 22px; }
    .float-cart__footer .buy-btn {
      color: #ececec;
      text-transform: uppercase;
      background-color: #0c0b10;
      text-align: center;
      padding: 15px 0;
      margin-top: 5px;
      cursor: pointer;
      transition: background-color 0.2s; }
      .float-cart__footer .buy-btn:hover {
        background-color: #000; }

/* MAC scrollbar para desktop*/
@media screen and (min-width: 640px) {
  .float-cart__content::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 10px; }
  .float-cart__content::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #0c0b10; } }

@media (min-width: 1025px) {
  .float-cart .bag--float-cart-closed {
    left: -90px;
    width: 90px; } }

.float-cart__content {
  height: 100%;
  overflow-y: auto; }

.spinner.lds-ring {
  position: fixed;
  top: 25%;
  left: 50%;
  margin-left: -64px;
  margin-top: -64px;
  width: 128px;
  height: 128px;
  z-index: 100; }
  .spinner.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100px;
    height: 100px;
    margin: 25px;
    border: 25px solid #1B3D78;
    border-radius: 50%;
    -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #1B3D78 transparent transparent transparent; }
    .spinner.lds-ring div:nth-child(1) {
      -webkit-animation-delay: -0.45s;
              animation-delay: -0.45s; }
    .spinner.lds-ring div:nth-child(2) {
      -webkit-animation-delay: -0.3s;
              animation-delay: -0.3s; }
    .spinner.lds-ring div:nth-child(3) {
      -webkit-animation-delay: -0.15s;
              animation-delay: -0.15s; }

@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.clsSearchHeader {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #FFFFFF; }

.shelf-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 99%;
  min-height: -webkit-fill-available; }
  .shelf-container-header {
    width: 100%; }
    .shelf-container-header .searchBox {
      float: left; }
    .shelf-container-header .sort {
      float: right; }
    .shelf-container-header .products-found {
      font-size: 80%;
      float: right;
      text-align: right;
      width: 100%; }
    .shelf-container-header .pullright {
      float: right; }
    .shelf-container-header .sort {
      float: right;
      text-align: right; }
      .shelf-container-header .sort select {
        background-color: #fff;
        outline: none;
        border: 1px solid #ececec;
        border-radius: 2px;
        margin-left: 10px;
        width: auto;
        height: 28px;
        cursor: pointer; }
        .shelf-container-header .sort select:hover {
          border: 1px solid #5b5a5e; }
  .shelf-container .shelf-item-border {
    border: 1px solid #ececec;
    padding: 2px;
    transition: 0.5s; }
    .shelf-container .shelf-item-border:hover {
      border: 1px solid #50B8BA; }
  .shelf-container .shelf-item__thumb {
    cursor: pointer;
    border-radius: 4px; }
  .shelf-container .shelf-item {
    width: 25%;
    position: relative;
    text-align: center;
    box-sizing: border-box;
    margin-bottom: 20px;
    padding: 4px; }
    .shelf-container .shelf-item:hover .shelf-item__buy-btn {
      background-color: #50B8BA; }
    .shelf-container .shelf-item .shelf-stopper {
      position: absolute;
      color: #ececec;
      top: 10px;
      right: 10px;
      padding: 5px;
      font-size: 0.6em;
      background-color: #127BAB;
      cursor: default; }
    .shelf-container .shelf-item__thumb img {
      width: 100%;
      border-radius: 4px; }
    .shelf-container .shelf-item__title {
      position: relative;
      padding: 0;
      padding-top: 10px;
      margin-top: 5px;
      font-size: 15px;
      overflow: hidden; }
    .shelf-container .shelf-item__price {
      height: 45px; }
      .shelf-container .shelf-item__price .val b {
        font-size: 1.5em;
        margin-left: 5px; }
      .shelf-container .shelf-item__price .installment {
        color: #9c9b9b; }
    .shelf-container .shelf-item__buy-btn {
      background-color: #127BAB;
      color: #fff;
      padding: 12px 0;
      margin-top: 10px;
      cursor: pointer;
      border-radius: 5px;
      transition: background-color 0.5s; }
    .shelf-container .shelf-item__buy-btn-disabled {
      background-color: #acacac;
      color: #fff;
      padding: 12px 0;
      margin-top: 10px;
      border-radius: 5px;
      transition: background-color 0.5s; }
  .shelf-container .shelf-single-item {
    width: 75%;
    position: relative;
    text-align: center;
    box-sizing: border-box;
    padding: 10px;
    margin-bottom: 30px;
    border: 1px solid transparent;
    cursor: pointer; }
    .shelf-container .shelf-single-item:hover {
      border: 2px solid #50B8BA; }
      .shelf-container .shelf-single-item:hover .shelf-item__buy-btn {
        background-color: #50B8BA; }
    .shelf-container .shelf-single-item .shelf-stopper {
      position: absolute;
      color: #ececec;
      top: 10px;
      right: 10px;
      padding: 5px;
      font-size: 0.6em;
      background-color: #127BAB;
      cursor: default; }
    .shelf-container .shelf-single-item__thumb img {
      width: 100%;
      border-radius: 4px; }
    .shelf-container .shelf-single-item__title {
      position: relative;
      padding: 0 20px;
      margin-top: 5px;
      padding: 0;
      font-size: 15px;
      line-height: 13px; }
      .shelf-container .shelf-single-item__title::before {
        content: '';
        width: 80%;
        height: 2px;
        background-color: #50B8BA;
        position: absolute;
        bottom: -5px;
        left: 15%;
        margin-left: -10px; }
    .shelf-container .shelf-single-item__price {
      height: 45px; }
      .shelf-container .shelf-single-item__price .val b {
        font-size: 1.5em;
        margin-left: 5px; }
      .shelf-container .shelf-single-item__price .installment {
        color: #9c9b9b; }
    .shelf-container .shelf-single-item__buy-btn {
      background-color: #127BAB;
      color: #fff;
      padding: 12px 0;
      margin-top: 10px;
      cursor: pointer;
      border-radius: 5px;
      transition: background-color 0.5s; }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  margin: 0;
  color: #1b1a20;
  font-family: 'Roboto', sans-serif; }

main {
  display: block;
  padding: 0;
  width: 98%;
  margin-left: 1%;
  margin: 0 auto 0 auto;
  height: 100%; }

.shelf-container-header {
  overflow: hidden;
  border-bottom: 1px solid #f1f1f1; }

.shelf-container {
  margin: 0 auto; }

.products-found {
  width: 50%;
  display: inline-block; }

.icon-link {
  padding-left: 20px; }

.header-left {
  float: left;
  text-align: left;
  width: 50%; }

.header-right {
  float: right;
  text-align: right;
  width: 50%; }

header a {
  color: #ffffff;
  font-size: 16px;
  font-weight: bold; }

.noproductsfound {
  color: #808080;
  width: 100%;
  text-align: center;
  padding-top: 50px; }

.clsNotification {
  cursor: pointer; }

.clsDiscountDisplay {
  position: absolute;
  top: 80px;
  right: 5px;
  height: 65px;
  width: 65px;
  font-size: 100%;
  line-height: 12px;
  padding-top: 18px;
  border-radius: 50%;
  border: 1px solid yellow;
  color: #000000;
  font-weight: bold;
  background-color: yellow; }

.k-card-actions {
  padding: 0;
  grid-gap: 0;
  grid-gap: 0;
  gap: 0; }

@media only screen and (max-width: 1024px) {
  body .filters {
    width: 20%; }
  body .shelf-container {
    width: 99%;
    margin: 0 auto; }
    body .shelf-container .shelf-item {
      width: 33.33%; }
    body .shelf-container .shelf-single-item {
      width: 50%; } }

@media only screen and (max-width: 768px) {
  body .filters {
    width: 25%; }
  body .category-items {
    display: block !important; }
  body .shelf-container {
    width: 99%; }
    body .shelf-container .shelf-item {
      width: 50%;
      padding: 10px; }
      body .shelf-container .shelf-item__title {
        margin-top: 5px;
        padding: 0; }
    body .shelf-container .shelf-single-item {
      width: 50%;
      padding: 10px; }
      body .shelf-container .shelf-single-item__title {
        margin-top: 5px;
        padding: 0; }
  body .float-cart {
    width: 100%;
    right: -100%;
    z-index: 100; }
    body .float-cart--open {
      right: 0; }
    body .float-cart__close-btn {
      left: 0px;
      z-index: 100;
      background-color: #1b1a20; }
    body .float-cart__header {
      padding: 6px 0; } }

@media only screen and (max-width: 460px) {
  body main {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 2%; } }

@media only screen and (max-width: 360px) {
  body .shelf-container .shelf-item {
    width: 100%;
    padding: 10px; } }


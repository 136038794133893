$vBorderRadious: 10px;
$vTileHeight: 300px;
$vLandingOfferWidth: 250px;

.container {
    width:100%;
    padding-bottom:25px;
}

.content {
  background-color: #ffffff;
}

.k-button {
  background-color:transparent !important;
  color:#FFFFFF !important;
  border: none !important;
  font-size: 18px !important;
}

.k-button > .k-icon {
  font-size: 28px;
}

.clsStickContainer {
    position: sticky;
    top: 64px;
    z-index: 10;
    background-color: #FFFFFF;
    padding-bottom: 10px;
    padding-top: 5px;
    margin-top: -5px;
}

.ant-layout-content {
    padding:0px;
}

.tileWrapper {
    display: 'flex';
    flex-direction: 'column';
    width:100%;
}

.tile {
    flex:1;
    float:left;
    padding:5px;
}

.tile-content {
    border:1px solid #C0C0C0;
    border-radius:$vBorderRadious;
    height:$vTileHeight;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.overlay {
    position:relative;
    width:100%;
    height:100%;
    border-radius:$vBorderRadious;
    background-color:rgba(0, 0, 0, 0.05);
    padding:25px;
    color:#FFFFFF;
    transition: 0.5s;
}

.text-container {
    border-top-left-radius:$vBorderRadious;
    border-bottom-left-radius:$vBorderRadious;
    background-color:rgba(27, 61, 120, 0.75);
    padding:5px;
    padding-right:30px;
    margin-top:$vTileHeight*0.5;
    margin-right: -25px;
    margin-left: 15%;
    text-align:right;
}

.overlay:hover {
    background-color:rgba(0, 0, 0, 0.13);
}

.titleWrapper {
    width:100%;
    height:40px;
    padding-top:2px;
    border-bottom:1px solid #C0C0C0;
}

.titleWrapper h1 {
    font-size: 140%;
    line-height: 1;
    margin:0;
    padding-top:5px;
}

.pullright {
    float:right;
}

.pullleft {
    float:left;
}

.searchTextbox {
    border: 1px solid #C0C0C0;
    border-radius: 0px!important;
    vertical-align: top;
    height: 28px!important;
}

.searchProductTextbox {
    border: 1px solid #C0C0C0;
    width: 80%;
    height: 40px!important;
    padding-left:8px;
    border-radius: 0px!important;
    vertical-align: top;
}

.searchProductButton {
    border: 1px solid #C0C0C0;
    width: 20%;
    height: 40px!important;
    vertical-align: top;
}

.searchProductButton:hover {
    background: #1890ff;
    color: #FFFFFF;
}

.notFound {
    color:#808080;
    padding-top:50px;
    text-align: center;
}

.clsSideMenu {
  width:250px;
  background-color: '#ffffff';
  overflow:auto;
  position:sticky;
  top:0;
  left:0;
  float:left;
}

.clsMainArea {
  background:'#fff';
  float: left;
}

.ant-menu-submenu-horizontal .ant-menu-submenu-title {
    height:65px!important;
    line-height:65px!important;
}

.ant-menu-submenu-horizontal .ant-menu-submenu-title:hover {
    color:#FFFFFF!important;
}

.clsOffer {
    min-height: 100px!important;
    height: auto!important;
}

.clsOffer .shelf-item {
    margin-bottom: 0px!important;
    width:$vLandingOfferWidth!important;
    float:left;
}

.clsOfferItemContainer {
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 15px;
}

.capitalizeFirstLetter {
    display: inline-block;
}

.capitalizeFirstLetter:first-letter {
    text-transform: uppercase;
}

.ant-table-tbody > tr > td {
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 90%;
}

.ant-table-thead > tr > th {
    padding-top: 3px;
    padding-bottom: 3px;
    background-color: #eeeeee;
}

div.toggleScroll-x, table.toggleScroll-x, td.toggleScroll-x, body.toggleScroll-x {
    overflow-x: hidden;
}
div.toggleScroll-x:hover, table.toggleScroll-x:hover, td.toggleScroll-x:hover, body.toggleScroll-x:hover {
    overflow-x: overlay;
}

div.toggleScroll-y, table.toggleScroll-y, td.toggleScroll-y, body.toggleScroll-y {
    overflow-y: hidden;
}
div.toggleScroll-y:hover, table.toggleScroll-y:hover, td.toggleScroll-y:hover, body.toggleScroll-y:hover {
    overflow-y: overlay;
}

.toggleScroll-x::-webkit-scrollbar {
	width:13px; 
}
.toggleScroll-x::-webkit-scrollbar * {
	background:transparent; 
}
.toggleScroll-x::-webkit-scrollbar-thumb {
	background:rgba(0,0,0,0.25) !important; 
}

.toggleScroll-y::-webkit-scrollbar {
	width:13px; 
}
.toggleScroll-y::-webkit-scrollbar * {
	background:transparent; 
}
.toggleScroll-y::-webkit-scrollbar-thumb {
	background:rgba(0,0,0,0.25) !important; 
}

@supports (-ms-ime-align:auto) {
    div.toggleScroll-x:hover, table.toggleScroll-x:hover, td.toggleScroll-x:hover {
		overflow-x: auto;
		-ms-overflow-style: -ms-autohiding-scrollbar;
		scrollbar-width: thin;
	}
	div.toggleScroll-y:hover, table.toggleScroll-y:hover, td.toggleScroll-y:hover {
		overflow-y: auto;
		-ms-overflow-style: -ms-autohiding-scrollbar;
		scrollbar-width: thin;
	}
}

@-moz-document url-prefix() {
	div.toggleScroll-x, table.toggleScroll-x, td.toggleScroll-x {
		overflow-x: auto;
	}
	div.toggleScroll-y, table.toggleScroll-y, td.toggleScroll-y {
		overflow-y: auto;
	}
}

.col-1 {width: 8.33%;}
.col-2 {width: 16.66%;}
.col-3 {width: 25%;}
.col-4 {width: 33.33%;}
.col-5 {width: 41.66%;}
.col-6 {width: 50%;}
.col-7 {width: 58.33%;}
.col-8 {width: 66.66%;}
.col-9 {width: 75%;}
.col-10 {width: 83.33%;}
.col-11 {width: 91.66%;}
.col-12 {width: 100%;}

@media only screen and (max-width: 768px) {
    /* For medium screens: */
    [class*="col-"] {
      width: 50%;
    }

    .filters {
        width:100%!important;
        text-align:left;
    }

    .ant-menu {
        width:100%!important;
    }

    .clsSideMenu {
        width:98%!important;
        margin-left:1%!important;
        height: auto!important;
        position:relative!important;
        border: 1px solid #ececec;
        float:none;
    }
    
    .clsMainArea {
      float: none;
    }

    .ant-menu-submenu-inline .ant-menu-submenu-title {
        height:20px!important;
        line-height: 20px!important;
    }

    .clsMainArea {
        margin-left:0px!important;
    }

    .searchProductTextbox {
        width:75%;
    }

    .searchProductButton {
        width:25%;
    }

    .searchProductTextbox, .searchProductButton {
        font-size:90%;
    }

    .container {
        padding-bottom:0px!important;
    }

    main {
        padding:0px!important;
        width:98%!important;
        margin-left:1%!important;
    }
}

@media only screen and (max-width: 640px) {
    /* For mobile phones: */
    [class*="col-"] {
        width: 100%;
    }
}


.headerWrapper {
	display: flex;
  margin: -2px auto 12px auto;
}

.ant-layout-header {
  background: #1B3D78!important;
  padding-left: 10px!important;
}

.logo-corner-mini img{
  display:none;
}

.quotesButton-mini {
    display:none !important;
}

.searchButton-mini {
    display:none !important;
}

.shelf-container-header{
padding: 6px 0;
}
.logo-corner h1{
  margin:0;
}
 input:not([type="range"]) {
    height: 38px;
    color:#333333;
    font-weight:400;
}

.logo-corner:hover .octo-arm {
  animation: octocat-wave 560ms ease-in-out;
}
.searchButton{
  height: 28px!important;
  border:1px solid #C0C0C0;
  width: 80px;
  vertical-align: top;
}
.searchButton:hover{
  background: #1890ff;
  color:#ffffff;
  border:1px solid #0971d1;
}
.searchTextbox {
  max-width: 200px;
}

.header-right {
  padding-left:20px;
}

@keyframes octocat-wave {
  0%,
  100% {
    transform: rotate(0);
  }
  20%,
  60% {
    transform: rotate(-25deg);
  }
  40%,
  80% {
    transform: rotate(10deg);
  }
}
@media (max-width: 500px) {
  .logo-corner:hover .octo-arm {
    animation: none;
  }
  .logo-corner .octo-arm {
    animation: octocat-wave 560ms ease-in-out;
  }
}

@media (min-width: 1025px) {
  .divSearchButton {
    display:none !important;
  }
}

@media (max-width: 1024px) {
  .searchButton-mini {
    display:block !important;
  }
  .divSearchButtonHeader {
    display:none !important;
  }
}

@media (max-width: 768px) {
  .ant-layout-header {
    padding: 0;
    background: #1B3D78!important;
  }
  .logo-corner img{
    display:none;
  }
  .logo-corner-mini img{
    display:block;
    width:auto;
  }
  .quotesButton {
    display:none !important;
  }
  .quotesButton-mini {
    display:block !important;
  }
  .searchTextbox {
    font-size: 90%;
    max-width: 100px;
  }
  .searchButton {
    font-size: 80%;
  }
  .sort select {
    font-size: 90%;
  }
  .header-left {
    width:20%!important;
  }
  .icon-link{
    padding: 0!important;
  }
  .header-left{
    height:60px;
  }
  .header-right{
    text-align: left!important;
    padding-left: 0px;
  }
  header a {
    font-size: 12px!important;
  }
  header {
    padding:0px!important;
  }
}

@media (max-width: 350px) {
  .logo-corner-mini img{
    height:35px;
    width:auto;
  }
  .clsLogoContainer .k-card .k-card-body {
    padding:0px;
    padding-left:15px;
  }
}

.clsCarouselElement {
  cursor: pointer;
}

.clsHotTopic {
  border: 1px solid #ffffff;
  color:#ffffff;
  float: left;
  width: 25%;
  height: 150px;
  line-height: 150px;
  text-align: center;
  vertical-align: middle;
  border-radius: 5px;
  margin-bottom: 15px;
  background-size:cover;
  background-position:center;
  cursor: pointer;
}

.clsHotTopic img {
  width: auto;
  height: 100%;
}

@media (max-width: 1024px) {
  .clsHotTopic {
    width: 50%;
    height: 110px;
    line-height: 110px;
  }
} 

@media (max-width: 768px) {
  .clsHotTopic {
    width: 50%;
    height: 80px;
    line-height: 80px;
  }
} 

.clsSearchHeader {
  width: 100%;
  position: sticky;
  top:0;
  z-index: 10;
  background-color: #FFFFFF;
}

.shelf-container {
  display: flex;
  flex-wrap: wrap;
  width: 99%;
  min-height: -webkit-fill-available;

  &-header {
    width: 100%;

    .searchBox {
      float: left;
    }
    .sort {
      float: right;
    }
    .products-found{
      font-size: 80%;
      float: right;
      text-align: right;
      width:100%;
    }
    .pullright {
      float: right;
    }

    .sort {
      float: right;
      text-align: right;

      select {
        background-color: #fff;
        outline: none;
        border: 1px solid #ececec;
        border-radius: 2px;
        margin-left: 10px;
        width: auto;
        height: 28px;
        cursor: pointer;

        &:hover {
          border: 1px solid #5b5a5e;
        }
      }
    }
  }

  .shelf-item-border {
    border: 1px solid #ececec;
    padding: 2px;
    transition: 0.5s;
    &:hover {
      border: 1px solid #50B8BA;
    }
  }

  .shelf-item__thumb {
    cursor:pointer;
    border-radius:4px;
  }

  .shelf-item {
    width: 25%;
    position: relative;
    text-align: center;
    box-sizing: border-box;
    margin-bottom: 20px;
    padding:4px;

    &:hover {

      .shelf-item__buy-btn {
        background-color: #50B8BA;
      }
    }

    .shelf-stopper {
      position: absolute;
      color: #ececec;
      top: 10px;
      right: 10px;
      padding: 5px;
      font-size: 0.6em;
      background-color: #127BAB;
      cursor: default;
    }

    &__thumb {
      img {
        width: 100%;
        border-radius: 4px;
      }
    }

    &__title {
      position: relative;
      padding: 0;
      padding-top: 10px;
      margin-top: 5px;
      font-size: 15px;
      overflow:hidden;
    }

    &__price {
      height: 45px;

      .val {
        b {
          font-size: 1.5em;
          margin-left: 5px;
        }
      }

      .installment {
        color: #9c9b9b;
      }
    }

    &__buy-btn {
      background-color: #127BAB;
      color: #fff;
      padding: 12px 0;
      margin-top: 10px;
      cursor: pointer;
      border-radius: 5px;
      // border-bottom: 2px solid #151419;

      transition: background-color 0.5s;
    }

    &__buy-btn-disabled {
      background-color: #acacac;
      color: #fff;
      padding: 12px 0;
      margin-top: 10px;
      border-radius: 5px;
      // border-bottom: 2px solid #151419;

      transition: background-color 0.5s;
    }
  }

  .shelf-single-item {
    width: 75%;
    position: relative;
    text-align: center;
    box-sizing: border-box;
    padding: 10px;
    margin-bottom: 30px;
    border: 1px solid transparent;
    cursor: pointer;

    &:hover {
      border: 2px solid #50B8BA;

      .shelf-item__buy-btn {
        background-color: #50B8BA;
      }
    }

    .shelf-stopper {
      position: absolute;
      color: #ececec;
      top: 10px;
      right: 10px;
      padding: 5px;
      font-size: 0.6em;
      background-color: #127BAB;
      cursor: default;
    }

    &__thumb {
      img {
        width: 100%;
        border-radius: 4px;
      }
    }

    &__title {
      position: relative;
      padding: 0 20px;
      margin-top: 5px;
      padding: 0;
      font-size: 15px;
      line-height: 13px;      

      &::before {
        content: '';
        width: 80%;
        height: 2px;
        background-color: #50B8BA;
        position: absolute;
        bottom: -5px;
        left: 15%;
        margin-left: -10px;
      }
    }

    &__price {
      height: 45px;

      .val {
        b {
          font-size: 1.5em;
          margin-left: 5px;
        }
      }

      .installment {
        color: #9c9b9b;
      }
    }

    &__buy-btn {
      background-color: #127BAB;
      color: #fff;
      padding: 12px 0;
      margin-top: 10px;
      cursor: pointer;
      border-radius: 5px;
      // border-bottom: 2px solid #151419;

      transition: background-color 0.5s;
    }
  }



}

@import url('https://fonts.googleapis.com/css?family=Roboto');

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  color: #1b1a20;
  font-family: 'Roboto', sans-serif;
}

main {
  display: block;
  padding: 0;
  width: 98%;
  margin-left: 1%;
  margin: 0 auto 0 auto;
  height:100%;
}

.shelf-container-header {
  overflow: hidden;
  border-bottom: 1px solid #f1f1f1;
}

.shelf-container {
  margin: 0 auto;  
}

.products-found{
  width: 50%;
  display: inline-block;
}
.icon-link{
  padding-left: 20px;
}
.header-left{
  float:left;
  text-align: left;
  width: 50%;
}
.header-right{
  float:right;
  text-align: right;
  width: 50%;
}
header a{
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
}

.noproductsfound {
  color:#808080;
  width:100%;
  text-align: center;
  padding-top:50px;
}

.clsNotification {
  cursor: pointer;
}

.clsDiscountDisplay {
  position:absolute;
  top:80px;
  right:5px;
  height: 65px;
  width: 65px;
  font-size:100%;
  line-height:12px;
  padding-top: 18px;
  border-radius: 50%;
  border:1px solid rgb(255, 255, 0);
  color:#000000;  
  font-weight: bold;
  background-color: rgba(255, 255, 0, 1);
}

.k-card-actions {
  padding:0;
  grid-gap: 0;
  gap:0;
}

@media only screen and (max-width: 1024px) {
  body {
    .filters {
      width: 20%;
    }

    .shelf-container {
      width: 99%;
      margin: 0 auto;

      .shelf-item {
        width: 33.33%;
      }

      .shelf-single-item {
        width: 50%;
      }

    }
  }
}

@media only screen and (max-width: 768px) {
  body {
    .filters {
      width: 25%;
    }

    .category-items {
      display:block!important;
    }

    .shelf-container {
      width: 99%;

      .shelf-item {
        width: 50%;
        padding: 10px;

        &__title {
          margin-top: 5px;
          padding: 0;
        }
      }

      .shelf-single-item {
        width: 50%;
        padding: 10px;

        &__title {
          margin-top: 5px;
          padding: 0;
        }
      }


    }

    .float-cart {
      width: 100%;
      right: -100%;
      z-index: 100; 

      &--open {
        right: 0;
      }

      &__close-btn {
        left: 0px;
        z-index: 100;
        background-color: #1b1a20;
      }

      &__header {
        padding: 6px 0;
      }
    }
  }
}

@media only screen and (max-width: 460px) {
  body {
    main {
      display: flex;
      flex-wrap: wrap;
      padding: 2%;
    }
  }
}

@media only screen and (max-width: 360px) {
  body {
    .shelf-container .shelf-item {
      width: 100%;
      padding: 10px;
    }
  }
}
